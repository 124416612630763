<template>
  <div>
    <b-card>
      <b-card-header
        style="padding-top: 0px; padding-right: 0px; padding-left: 0px"
      >
        <div class="d-flex align-items-center">
          <b-card-title>
            <h4><b style="color: cornflowerblue">Edit Pelajaran Tryout</b></h4>
          </b-card-title>
        </div>
        <b-nav>
          <b-nav-item>
            <router-link to="/admin/tinjauan_sembilan">
              <b-button variant="primary">
                <feather-icon icon="ArrowLeftIcon" style="color: white" />
                <span class="align-right"
                  ><b style="color: white"> Kembali</b></span
                >
              </b-button>
            </router-link>
          </b-nav-item>
        </b-nav>
      </b-card-header>
      <b-form
        method="post"
        enctype="multipart/form-data"
        @submit.prevent="updateData(tinjauan_sembilan)"
      >
        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Nama</b></label>
            <b-form-input
              id="nama"
              v-model="tinjauan_sembilan.nama"
              placeholder="Nama"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Deskripsi</b></label>
            <b-form-input
              id="deskripsi"
              v-model="tinjauan_sembilan.deskripsi"
              placeholder="Deskripsi"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="12" class="mb-1">
            <label for="textarea-default"><b>Catatan</b></label>
            <b-form-input
              id="catatan"
              v-model="tinjauan_sembilan.catatan"
              placeholder="Catatan"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6" xl="4" class="mb-1" />
          <b-col md="6" xl="2" class="mb-1">
            <b-button variant="success" style="width: 100%" type="submit">
              <feather-icon icon="CheckCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Simpan</b></span
              >
            </b-button>
          </b-col>
          <b-col md="6" xl="2" class="mb-1">
            <b-button
              variant="danger"
              style="width: 100%"
              type="button"
              @click="cancelButton('info')"
            >
              <feather-icon icon="XCircleIcon" style="color: white" />
              <span class="align-middle"
                ><b style="color: white"> Batal</b></span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCard,
  BButton,
  BForm,
  BToast,
  BCardHeader,
  BNav,
  BCardTitle,
  BFormSelect,
  BNavItem,
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
export default {
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTr,
    BForm,
    BTh,
    BToast,
    BTd,
    ToastificationContent,
    BTbody,
    Swal,
    BTfoot,
    BButton,
    BCardHeader,
    BNav,
    BFormTextarea,
    BNavItem,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    vSelect,
  },
  data() {
    return {
      tinjauan_sembilan: [],
      id: this.$route.params.id,
      nama: "",
      deskripsi: "",
      catatan: "",
    };
  },
  async created() {
    await this.$http
      .get(
        process.env.VUE_APP_BACKEND_URL +
          `tinjauan_sembilan/search/${this.$route.params.id}`
      )
      .then((response) => {
        this.tinjauan_sembilan = response.data.data;
        // console.log("cek test", this.tinjauan_sembilan);
      });
  },
  mounted(){
    this.checkAuth();
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      var levelCheck = localStorage.getItem("Ulevel");
      if (sessionCheck == null || sessionCheck == "") {
                    this.$router.push({ name: "login" });
      }
      else if(levelCheck != 2){
            this.$router.push({ name: "dashboard" });
      }
    },
    async updateData(tinjauan_sembilan) {
      await Swal.fire({
        title: "Apa sudah selesai?",
        text: "Perubahan Data diakhiri",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, sudah selesai!",
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .put(
                process.env.VUE_APP_BACKEND_URL +
                  `tinjauan_sembilan/update/${this.$route.params.id}`,
                {
                  nama: (this.tinjauan_sembilan.nama = tinjauan_sembilan.nama),
                  deskripsi: (this.tinjauan_sembilan.deskripsi = tinjauan_sembilan.deskripsi),
                  catatan: (this.tinjauan_sembilan.catatan = tinjauan_sembilan.catatan),
                }
              )
              .then((result) => {
                console.log(result);
              });
          }
        })
        .then((result) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Simpan Perubahan Data",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
          this.$router.push({ name: "list_tinjauan_sembilan" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelButton(variant = null) {
      this.nama = "";
      this.deskripsi = "";
      this.catatan = "";
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "XCircleIcon",
            text: "Cancel Perubahan Data",
            variant: "info",
          },
        },
        {
          timeout: 5000,
        },
        {
          position: "top-right",
        }
      );
      this.$router.push({ name: "list_tinjauan_sembilan" });
    },
  },
};
</script>
